@tailwind components;

@layer components {
  .mfc__labeled-floating-btn {
    @apply mfc__flex mfc__flex-col mfc__items-center;
    @apply mfc__space-y-2;
    @apply mfc__text-sm;
    @apply mfc__overflow-hidden;
  }

  .mfc__labeled-floating-btn label {
    @apply mfc__truncate;
    @apply mfc__max-w-full;
  }
}
