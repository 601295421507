@tailwind components;

@layer components {
  .mfc__personalize-edit-button-section {
    @apply mfc__grid mfc__grid-cols-1 mfc__gap-2;
    @apply mfc__w-full;

    @apply xs:mfc__grid-cols-2;
  }
}
