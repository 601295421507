@tailwind components;

@layer components {
  .mfc__modal-picker {
    @apply mfc__invisible;
    @apply mfc__z-[1002];
    @apply mfc__transition-transform;
    @apply mfc__absolute mfc__inset-0;
    @apply mfc__flex mfc__justify-center;
    @apply mfc__w-full mfc__h-full;
    @apply mfc__overflow-y-auto;
    @apply mfc__translate-y-full mfc__translate-x-0;
    @apply mfc__text-center;
    @apply mfc__bg-gray-100;

    /* Desktop */
    @apply lg:mfc__rounded-r-xl;
    @apply lg:mfc__shadow-none;
    @apply lg:mfc__translate-x-full lg:mfc__translate-y-0;
  }

  .mfc__modal-picker-close {
    @apply mfc__z-[1002];
    @apply mfc__absolute mfc__top-8 mfc__right-8;
  }

  .mfc__modal-picker-inner {
    @apply mfc__p-11;
    @apply mfc__w-[26.25rem];

    /* Desktop */
    @apply lg:mfc__w-full;
  }

  .mfc__modal-picker-inner > h1 {
    @apply mfc__pb-11;
  }

  .mfc__modal-picker-buttons {
    @apply mfc__w-full;
    @apply mfc__grid mfc__grid-cols-1 mfc__gap-2;
    @apply mfc__mt-11;

    @apply xs:mfc__grid-cols-2;
  }

  .mfc__modal-picker-buttons button {
    @apply mfc__grow;
  }

  /* States */
  .mfc__modal-picker-visible {
    @apply mfc__visible;
    @apply mfc__translate-y-0;

    /* Desktop */
    @apply lg:mfc__translate-x-0;
  }
}
