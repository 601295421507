@tailwind components;

@layer components {
  .mfc__loader {
    @apply mfc__absolute mfc__inset-0;
    @apply mfc__flex mfc__items-center mfc__justify-center mfc__flex-col mfc__space-y-2;
    @apply mfc__bg-white;
  }

  /* Characteristics */
  .mfc__loader-background-transparent {
    @apply mfc__bg-opacity-60;
  }
}
