@tailwind components;

@layer components {
  .mfc__floating-btn {
    @apply mfc__relative;
    @apply mfc__w-12 mfc__h-12;
  }

  .mfc__floating-btn-inner,
  button.mfc__floating-btn-inner {
    @apply mfc__transition-transform;
    @apply mfc__cursor-pointer;
    @apply mfc__w-full mfc__h-full;
    @apply mfc__inline-flex mfc__items-center mfc__justify-center;
    @apply mfc__bg-white mfc__rounded-xl;
    @apply mfc__border-2 mfc__border-white;
    @apply mfc__shadow-md;
    @apply mfc__text-sm mfc__font-medium;

    /* States */
    @apply hover:mfc__shadow-lg hover:mfc__ring-1 hover:mfc__ring-gray-100;

    @apply active:mfc__ring-2 active:mfc__ring-gray-300 active:mfc__shadow-sm;

    @apply disabled:mfc__opacity-40 disabled:mfc__cursor-default;
    @apply disabled:active:mfc__ring-0 disabled:active:mfc__shadow-none;
    @apply disabled:hover:mfc__shadow-md disabled:hover:mfc__ring-0;
  }

  .mfc__floating-btn-inner > * {
    @apply mfc__max-w-[1.75rem];
  }

  /* Characteristics */
  .mfc__floating-btn-large {
    @apply mfc__w-20 mfc__h-20;
  }

  .mfc__floating-btn-large .mfc__floating-btn-inner > * {
    @apply mfc__max-w-[4rem];
  }

  .mfc__floating-btn-large .mfc__floating-btn-inner,
  .mfc__floating-btn-large button.mfc__floating-btn-inner {
    @apply mfc__border-4;
  }

  .mfc__floating-btn-rounded-full .mfc__floating-btn-inner,
  .mfc__floating-btn-rounded-full button.mfc__floating-btn-inner {
    @apply mfc__rounded-full;
  }

  .mfc__floating-btn.mfc__floating-btn-lowcontrast .mfc__floating-btn-inner,
  .mfc__floating-btn.mfc__floating-btn-lowcontrast
    button.mfc__floating-btn-inner {
    @apply mfc__border-gray-100;
  }

  /* States */
  .mfc__floating-btn-inner:active:not(:disabled) > * {
    @apply mfc__translate-y-[0.5px];
  }

  .mfc__floating-btn.mfc__floating-btn-active .mfc__floating-btn-inner {
    @apply mfc__outline mfc__outline-1 mfc__outline-gray-900;
  }
}
