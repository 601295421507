@tailwind components;

@layer components {
  .mfc__spinner {
    @apply mfc__inline mfc__animate-mfc__spin;
    @apply mfc__w-4 mfc__h-4;
    @apply mfc__fill-black mfc__text-gray-200;
  }

  .mfc__spinner-large {
    @apply mfc__w-8 mfc__h-8;
  }

  /* Characteristics */
  .mfc__spinner-primary {
    @apply mfc__fill-melco-red;
  }
}
