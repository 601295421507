@tailwind components;

@layer components {
  .mfc__modal-error {
    @apply mfc__z-[1003];
    @apply mfc__absolute mfc__inset-0;
    @apply mfc__flex mfc__items-center mfc__justify-center mfc__flex-col mfc__space-y-2;
    @apply mfc__bg-white;
    @apply mfc__text-center;
  }

  .mfc__modal-error-buttons {
    @apply mfc__pt-4;
    @apply mfc__grid mfc__grid-cols-2 mfc__gap-2;
    @apply mfc__flex mfc__justify-center;
  }
  .mfc__modal-error-buttons button {
    @apply mfc__grow;
  }
}
