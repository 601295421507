@tailwind components;

@layer components {
  .mfc__configurator {
    @apply mfc__flex-auto;
    @apply mfc__relative;
    @apply mfc__overflow-hidden;
    @apply mfc__min-h-[18.75rem];
  }

  .mfc__configurator-action-buttons {
    @apply mfc__z-20;
    @apply mfc__absolute;
    @apply mfc__p-8;

    @apply mfc__w-full;
    @apply mfc__flex mfc__justify-center;
    @apply mfc__bottom-0;
    @apply mfc__invisible;

    /* Desktop */
    @apply lg:mfc__visible;
  }
  .mfc__configurator-action-buttons-inner {
    @apply mfc__grid mfc__grid-cols-2 mfc__gap-2;
  }
}
