@tailwind components;

@layer components {
  .mfc__btn,
  button.mfc__btn {
    @apply mfc__cursor-pointer;
    @apply mfc__transition-shadow;
    @apply mfc__flex mfc__justify-center mfc__items-center mfc__space-x-2;
    @apply mfc__h-10 mfc__py-2.5 mfc__px-10;
    @apply mfc__bg-white mfc__rounded-full mfc__border mfc__border-gray-900;

    /* States */
    @apply hover:mfc__shadow-md;

    @apply active:mfc__z-10 active:mfc__ring-2 active:mfc__ring-gray-300 active:mfc__shadow-sm;

    @apply disabled:mfc__opacity-40 disabled:mfc__cursor-default;
    @apply disabled:hover:mfc__shadow-none;
    @apply disabled:active:mfc__z-0 disabled:active:mfc__ring-0 disabled:active:mfc__shadow-none;
  }

  .mfc__btn-inner {
    @apply mfc__whitespace-nowrap mfc__overflow-hidden mfc__text-ellipsis;
    @apply mfc__text-sm mfc__font-medium;

    /* States */
    @apply active:mfc__translate-y-[0.5px];
  }

  .mfc__btn:disabled .mfc__btn-inner {
    @apply active:mfc__translate-y-0;
  }

  /* Characteristics */
  .mfc__btn-primary,
  button.mfc__btn-primary {
    @apply mfc__border-melco-red mfc__text-melco-red;

    /* States */
    @apply active:mfc__ring-red-200;

    @apply disabled:mfc__ring-0;
  }
}
