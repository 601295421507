@tailwind components;

@layer components {
  .mfc__configurator-view-selector {
    @apply mfc__z-20;
    @apply mfc__absolute;
    @apply mfc__p-8;

    @apply mfc__flex;
  }
}
