@import "//fast.fonts.net/t/1.css?apiType=css&projectid=832196c4-86bd-450e-ba18-2fb3cf7bd85c";
#melco-configurator .mfc__spinner {
  display: inline;
}

@keyframes mfc__mfc__spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

#melco-configurator .mfc__spinner {
  height: 16px;
  width: 16px;
  fill: #000;
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
  animation: 1s linear infinite mfc__mfc__spin;
}

#melco-configurator .mfc__spinner-large {
  height: 32px;
  width: 32px;
}

#melco-configurator .mfc__spinner-primary {
  fill: #eb0000;
}

#melco-configurator .mfc__loader {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

#melco-configurator .mfc__loader > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(8px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(8px * var(--tw-space-y-reverse));
}

#melco-configurator .mfc__loader {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

#melco-configurator .mfc__loader-background-transparent {
  --tw-bg-opacity: .6;
}

#melco-configurator .mfc__floating-btn {
  height: 48px;
  width: 48px;
  position: relative;
}

#melco-configurator .mfc__floating-btn-inner, #melco-configurator button.mfc__floating-btn-inner {
  cursor: pointer;
  height: 100%;
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  font-family: Helvetica Neue LT W05_65 Medium, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-flex;
}

#melco-configurator .mfc__floating-btn-inner:hover, #melco-configurator button.mfc__floating-btn-inner:hover {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(243 244 246 / var(--tw-ring-opacity));
}

#melco-configurator .mfc__floating-btn-inner:active, #melco-configurator button.mfc__floating-btn-inner:active {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity));
}

#melco-configurator .mfc__floating-btn-inner:disabled, #melco-configurator button.mfc__floating-btn-inner:disabled {
  cursor: default;
  opacity: .4;
}

#melco-configurator .mfc__floating-btn-inner:active:disabled, #melco-configurator button.mfc__floating-btn-inner:active:disabled {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

#melco-configurator .mfc__floating-btn-inner:hover:disabled, #melco-configurator button.mfc__floating-btn-inner:hover:disabled {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

#melco-configurator .mfc__floating-btn-inner > * {
  max-width: 28px;
}

#melco-configurator .mfc__floating-btn-large {
  height: 80px;
  width: 80px;
}

#melco-configurator .mfc__floating-btn-large .mfc__floating-btn-inner > * {
  max-width: 64px;
}

#melco-configurator .mfc__floating-btn-large .mfc__floating-btn-inner, #melco-configurator .mfc__floating-btn-large button.mfc__floating-btn-inner {
  border-width: 4px;
}

#melco-configurator .mfc__floating-btn-rounded-full .mfc__floating-btn-inner, #melco-configurator .mfc__floating-btn-rounded-full button.mfc__floating-btn-inner {
  border-radius: 9999px;
}

#melco-configurator .mfc__floating-btn.mfc__floating-btn-lowcontrast .mfc__floating-btn-inner, #melco-configurator .mfc__floating-btn.mfc__floating-btn-lowcontrast button.mfc__floating-btn-inner {
  --tw-border-opacity: 1;
  border-color: rgb(243 244 246 / var(--tw-border-opacity));
}

#melco-configurator .mfc__floating-btn-inner:active:not(:disabled) > * {
  --tw-translate-y: .5px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#melco-configurator .mfc__floating-btn.mfc__floating-btn-active .mfc__floating-btn-inner {
  outline: 1px solid #111827;
}

#melco-configurator .mfc__modal-backdrop {
  z-index: 1000;
  height: 100%;
  width: 100%;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
  --tw-bg-opacity: .5;
  position: fixed;
  inset: 0;
  overflow-y: auto;
}

#melco-configurator .mfc__modal-sidebar-scroll-indicator {
  position: absolute;
  bottom: 44px;
}

@keyframes mfc__mfc__bounce {
  0%, 100% {
    animation-timing-function: cubic-bezier(.8, 0, 1, 1);
    transform: translateY(-25%);
  }

  50% {
    animation-timing-function: cubic-bezier(0, 0, .2, 1);
    transform: translateY(0);
  }
}

#melco-configurator .mfc__modal-sidebar-scroll-indicator {
  opacity: .4;
  animation: 1s linear infinite mfc__mfc__bounce;
}

#melco-configurator .mfc__modal-sidebar-scroll-indicator > svg {
  --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

#melco-configurator .mfc__modal-sidebar {
  z-index: 20;
  height: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  text-align: center;
  --tw-shadow: 0 -2px 20px -10px #0006;
  --tw-shadow-colored: 0 -2px 20px -10px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-top-width: 1px;
  justify-content: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media (min-width: 1024px) {
  #melco-configurator .mfc__modal-sidebar {
    width: 420px;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    border-top-width: 0;
    border-left-width: 1px;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }
}

#melco-configurator .mfc__modal-sidebar-inner {
  width: 420px;
}

@media (min-width: 1024px) {
  #melco-configurator .mfc__modal-sidebar-inner {
    width: 100%;
  }
}

#melco-configurator .mfc__modal-sidebar-inner > h1 {
  padding: 44px 44px 0;
}

#melco-configurator .mfc__modal-sidebar-inner {
  height: 100%;
  overflow-y: auto;
}

#melco-configurator .mfc__modal {
  z-index: 1001;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 1;
  display: flex;
  position: fixed;
  inset: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

#melco-configurator .mfc__modal-inner {
  height: 100%;
  width: 100%;
  max-width: 1024px;
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  position: relative;
  overflow: hidden;
}

@media (min-width: 1024px) {
  #melco-configurator .mfc__modal-inner {
    height: 66.6667%;
    border-radius: 12px;
  }
}

@media (min-width: 1280px) {
  #melco-configurator .mfc__modal-inner {
    max-width: 1280px;
  }
}

#melco-configurator .mfc__modal-close {
  z-index: 1002;
  position: absolute;
  top: 32px;
  right: 32px;
}

#melco-configurator .mfc__loader {
  z-index: 1004;
}

#melco-configurator .mfc__modal-content {
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media (min-width: 1024px) {
  #melco-configurator .mfc__modal-content {
    flex-direction: row;
  }
}

#melco-configurator .mfc__switch {
  height: 48px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: 9999px;
}

#melco-configurator .mfc__switch-disabled {
  opacity: .4;
}

#melco-configurator .mfc__switch-item {
  height: 100%;
  width: 48px;
  border-radius: 9999px;
  justify-content: center;
  align-items: center;
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-flex;
}

#melco-configurator .mfc__switch-item:hover {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(243 244 246 / var(--tw-border-opacity));
}

#melco-configurator .mfc__switch-item:active {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#melco-configurator .mfc__switch-item:hover:disabled {
  border-width: 0;
}

#melco-configurator .mfc__switch-item:active:disabled {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-width: 0;
}

#melco-configurator .mfc__switch-item > * {
  max-width: 28px;
}

#melco-configurator .mfc__switch-item:not(:disabled) > :active {
  z-index: 1000;
  --tw-translate-y: .5px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#melco-configurator .mfc__switch-item.mfc__switch-item-active {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(17 24 39 / var(--tw-border-opacity));
}

#melco-configurator .mfc__switch-item.mfc__switch-item-active:hover {
  --tw-border-opacity: 1;
  border-color: rgb(156 163 175 / var(--tw-border-opacity));
}

#melco-configurator .mfc__switch-item.mfc__switch-item-active:active {
  --tw-border-opacity: 1;
  border-color: rgb(17 24 39 / var(--tw-border-opacity));
}

#melco-configurator .mfc__switch-item.mfc__switch-item-active:hover:disabled {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(17 24 39 / var(--tw-border-opacity));
}

#melco-configurator .mfc__configurator-view-selector {
  z-index: 20;
  padding: 32px;
  display: flex;
  position: absolute;
}

#melco-configurator .mfc__configurator-zoom-button {
  z-index: 20;
  padding: 32px;
  position: absolute;
  bottom: 0;
  right: 0;
}

@media (min-width: 1024px) {
  #melco-configurator .mfc__configurator-zoom-button {
    top: 0;
  }

  #melco-configurator .mfc__configurator_view_mode .mfc__configurator-zoom-button {
    right: 64px;
  }
}

#melco-configurator .mfc__configurator-preview {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

#melco-configurator .mfc__btn, #melco-configurator button.mfc__btn {
  cursor: pointer;
  justify-content: center;
  align-items: center;
  transition-property: box-shadow;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

#melco-configurator .mfc__btn > :not([hidden]) ~ :not([hidden]), #melco-configurator button.mfc__btn > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(8px * var(--tw-space-x-reverse));
  margin-left: calc(8px * calc(1 - var(--tw-space-x-reverse)));
}

#melco-configurator .mfc__btn, #melco-configurator button.mfc__btn {
  height: 40px;
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(17 24 39 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-radius: 9999px;
  padding: 10px 40px;
}

#melco-configurator .mfc__btn:hover, #melco-configurator button.mfc__btn:hover {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#melco-configurator .mfc__btn:active, #melco-configurator button.mfc__btn:active {
  z-index: 10;
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity));
}

#melco-configurator .mfc__btn:disabled, #melco-configurator button.mfc__btn:disabled {
  cursor: default;
  opacity: .4;
}

#melco-configurator .mfc__btn:hover:disabled, #melco-configurator button.mfc__btn:hover:disabled {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#melco-configurator .mfc__btn:active:disabled, #melco-configurator button.mfc__btn:active:disabled {
  z-index: 0;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

#melco-configurator .mfc__btn-inner {
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Helvetica Neue LT W05_65 Medium, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  overflow: hidden;
}

#melco-configurator .mfc__btn-inner:active {
  --tw-translate-y: .5px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#melco-configurator .mfc__btn:disabled .mfc__btn-inner:active {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#melco-configurator .mfc__btn-primary, #melco-configurator button.mfc__btn-primary {
  --tw-border-opacity: 1;
  border-color: rgb(235 0 0 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(235 0 0 / var(--tw-text-opacity));
}

#melco-configurator .mfc__btn-primary:active, #melco-configurator button.mfc__btn-primary:active {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(254 202 202 / var(--tw-ring-opacity));
}

#melco-configurator .mfc__btn-primary:disabled, #melco-configurator button.mfc__btn-primary:disabled {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

#melco-configurator .mfc__configurator {
  min-height: 300px;
  flex: auto;
  position: relative;
  overflow: hidden;
}

#melco-configurator .mfc__configurator-action-buttons {
  z-index: 20;
  width: 100%;
  visibility: hidden;
  justify-content: center;
  padding: 32px;
  display: flex;
  position: absolute;
  bottom: 0;
}

@media (min-width: 1024px) {
  #melco-configurator .mfc__configurator-action-buttons {
    visibility: visible;
  }
}

#melco-configurator .mfc__configurator-action-buttons-inner {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 8px;
  display: grid;
}

#melco-configurator .mfc__personalize-edit-section {
  text-align: center;
}

#melco-configurator .mfc__personalize-edit-section:not(:first-of-type):before {
  content: var(--tw-content);
  border-top-width: 1px;
  margin-top: 32px;
  display: block;
}

#melco-configurator .mfc__personalize-edit-section-inner {
  flex-wrap: wrap;
  gap: 8px;
  margin: 44px;
  display: flex;
}

#melco-configurator .mfc__personalize-edit-section-inner h2 {
  flex-basis: 100%;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 24px;
}

#melco-configurator .mfc__personalize-edit-section button {
  flex-grow: 1;
}

#melco-configurator .mfc__personalize-edit-button-section {
  width: 100%;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 8px;
  display: grid;
}

@media (min-width: 480px) {
  #melco-configurator .mfc__personalize-edit-button-section {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

#melco-configurator .mfc__personalize-edit-item {
  flex-grow: 1;
  overflow: hidden;
}

#melco-configurator .mfc__personalize-edit-item .mfc__personalize-edit-item-inner {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-width: 1px;
  border-radius: 6px;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  padding: 8px;
  display: flex;
}

#melco-configurator .mfc__personalize-edit-item-error .mfc__personalize-edit-item-inner {
  --tw-border-opacity: 1;
  border-color: rgb(235 0 0 / var(--tw-border-opacity));
}

#melco-configurator .mfc__personalize-edit-item-error .mfc__personalize-edit-item-help, #melco-configurator .mfc__personalize-edit-item-error .mfc__personalize-edit-item-inner-suffix {
  --tw-text-opacity: 1;
  color: rgb(235 0 0 / var(--tw-text-opacity));
}

#melco-configurator .mfc__personalize-edit-item-help {
  text-align: left;
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
  padding: 8px 16px;
  font-size: 14px;
  line-height: 20px;
}

#melco-configurator .mfc__personalize-edit-item-inner-suffix {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
  font-size: 14px;
  line-height: 20px;
}

#melco-configurator .mfc__personalize-edit-item-full {
  flex-basis: 100%;
}

#melco-configurator .mfc__personalize-edit-item-half {
  flex-basis: calc(50% - 8px);
}

#melco-configurator .mfc__labeled-floating-btn {
  flex-direction: column;
  align-items: center;
  display: flex;
}

#melco-configurator .mfc__labeled-floating-btn > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(8px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(8px * var(--tw-space-y-reverse));
}

#melco-configurator .mfc__labeled-floating-btn {
  font-size: 14px;
  line-height: 20px;
  overflow: hidden;
}

#melco-configurator .mfc__labeled-floating-btn label {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
}

#melco-configurator .mfc__modal-picker {
  visibility: hidden;
  z-index: 1002;
  height: 100%;
  width: 100%;
  --tw-translate-y: 100%;
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  text-align: center;
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  justify-content: center;
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
  position: absolute;
  inset: 0;
  overflow-y: auto;
}

@media (min-width: 1024px) {
  #melco-configurator .mfc__modal-picker {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    --tw-translate-x: 100%;
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }
}

#melco-configurator .mfc__modal-picker-inner {
  width: 420px;
  padding: 44px;
}

@media (min-width: 1024px) {
  #melco-configurator .mfc__modal-picker-inner {
    width: 100%;
  }
}

#melco-configurator .mfc__modal-picker-inner > h1 {
  padding-bottom: 44px;
}

#melco-configurator .mfc__modal-picker-buttons {
  width: 100%;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 8px;
  margin-top: 44px;
  display: grid;
}

@media (min-width: 480px) {
  #melco-configurator .mfc__modal-picker-buttons {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

#melco-configurator .mfc__modal-picker-buttons button {
  flex-grow: 1;
}

#melco-configurator .mfc__modal-picker-visible {
  visibility: visible;
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@media (min-width: 1024px) {
  #melco-configurator .mfc__modal-picker-visible {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}

#melco-configurator .mfc__floating-btn-grid {
  justify-content: center;
  display: flex;
}

#melco-configurator .mfc__floating-btn-grid-labeled {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-width: 1px;
  border-radius: 6px;
  flex-basis: 100%;
  margin-top: 16px;
  padding: 16px;
}

#melco-configurator .mfc__floating-btn-grid-inner {
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 16px;
  display: grid;
}

@media (min-width: 480px) {
  #melco-configurator .mfc__floating-btn-grid-inner {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

#melco-configurator .mfc__floating-btn-grid-inner-small {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

#melco-configurator .mfc__input-field {
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 4px 8px;
  font-size: 16px;
  line-height: 1;
}

#melco-configurator .mfc__input-field:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}



#melco-configurator .mfc__modal-error {
  z-index: 1003;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

#melco-configurator .mfc__modal-error > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(8px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(8px * var(--tw-space-y-reverse));
}

#melco-configurator .mfc__modal-error {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  text-align: center;
}

#melco-configurator .mfc__modal-error-buttons {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  justify-content: center;
  gap: 8px;
  padding-top: 16px;
  display: flex;
}

#melco-configurator .mfc__modal-error-buttons button {
  flex-grow: 1;
}

#melco-configurator .mfc__confirm-backdrop {
  z-index: 1001;
  height: 100%;
  width: 100%;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity));
  --tw-bg-opacity: .8;
  position: fixed;
  inset: 0;
  overflow-y: auto;
}

#melco-configurator .mfc__confirm {
  z-index: 1002;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  inset: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

#melco-configurator .mfc__confirm-inner {
  width: auto;
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-radius: 12px;
  margin: 16px;
  position: relative;
  overflow: hidden;
}

#melco-configurator .mfc__confirm-content {
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

#melco-configurator .mfc__confirm-content > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(8px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(8px * var(--tw-space-y-reverse));
}

#melco-configurator .mfc__confirm-content {
  text-align: center;
  padding: 32px;
  font-size: 16px;
  line-height: 24px;
}

#melco-configurator .mfc__confirm-title {
  font-family: Helvetica Neue LT W05_65 Medium, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

#melco-configurator .mfc__confirm-buttons {
  justify-content: center;
  gap: 8px;
  padding-top: 16px;
  display: flex;
}

#melco-configurator *, #melco-configurator :before, #melco-configurator :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

#melco-configurator :before, #melco-configurator :after {
  --tw-content: "";
}

#melco-configurator html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-family: Helvetica Neue LT W05_55 Roman, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

#melco-configurator body {
  line-height: inherit;
  margin: 0;
}

#melco-configurator hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

#melco-configurator abbr:where([title]) {
  text-decoration: underline dotted;
}

#melco-configurator h1, #melco-configurator h2, #melco-configurator h3, #melco-configurator h4, #melco-configurator h5, #melco-configurator h6 {
  font-size: inherit;
  font-weight: inherit;
}

#melco-configurator a {
  color: inherit;
  text-decoration: inherit;
}

#melco-configurator b, #melco-configurator strong {
  font-weight: bolder;
}

#melco-configurator code, #melco-configurator kbd, #melco-configurator samp, #melco-configurator pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

#melco-configurator small {
  font-size: 80%;
}

#melco-configurator sub, #melco-configurator sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

#melco-configurator sub {
  bottom: -.25em;
}

#melco-configurator sup {
  top: -.5em;
}

#melco-configurator table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

#melco-configurator button, #melco-configurator input, #melco-configurator optgroup, #melco-configurator select, #melco-configurator textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

#melco-configurator button, #melco-configurator select {
  text-transform: none;
}

#melco-configurator button, #melco-configurator [type="button"], #melco-configurator [type="reset"], #melco-configurator [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

#melco-configurator :-moz-focusring {
  outline: auto;
}

#melco-configurator :-moz-ui-invalid {
  box-shadow: none;
}

#melco-configurator progress {
  vertical-align: baseline;
}

#melco-configurator ::-webkit-inner-spin-button, #melco-configurator ::-webkit-outer-spin-button {
  height: auto;
}

#melco-configurator [type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

#melco-configurator ::-webkit-search-decoration {
  -webkit-appearance: none;
}

#melco-configurator ::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

#melco-configurator summary {
  display: list-item;
}

#melco-configurator blockquote, #melco-configurator dl, #melco-configurator dd, #melco-configurator h1, #melco-configurator h2, #melco-configurator h3, #melco-configurator h4, #melco-configurator h5, #melco-configurator h6, #melco-configurator hr, #melco-configurator figure, #melco-configurator p, #melco-configurator pre {
  margin: 0;
}

#melco-configurator fieldset {
  margin: 0;
  padding: 0;
}

#melco-configurator legend {
  padding: 0;
}

#melco-configurator ol, #melco-configurator ul, #melco-configurator menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

#melco-configurator textarea {
  resize: vertical;
}

#melco-configurator input::placeholder, #melco-configurator textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

#melco-configurator button, #melco-configurator [role="button"] {
  cursor: pointer;
}

#melco-configurator :disabled {
  cursor: default;
}

#melco-configurator img, #melco-configurator svg, #melco-configurator video, #melco-configurator canvas, #melco-configurator audio, #melco-configurator iframe, #melco-configurator embed, #melco-configurator object {
  vertical-align: middle;
  display: block;
}

#melco-configurator img, #melco-configurator video {
  max-width: 100%;
  height: auto;
}

#melco-configurator [hidden] {
  display: none;
}

@font-face {
  font-family: Helvetica Neue LT W05_55 Roman;
  src: url("08b57253-2e0d-4c12-9c57-107f6c67bc49.c4e39e23.woff2") format("woff2"), url("08edde9d-c27b-4731-a27f-d6cd9b01cd06.5bf04bc5.woff") format("woff");
}

@font-face {
  font-family: Helvetica Neue LT W05_65 Medium;
  src: url("240c57a0-fdce-440d-9ce3-85e0cb56f470.72c0764d.woff2") format("woff2"), url("7802e576-2ffa-4f22-a409-534355fbea79.bb8486f8.woff") format("woff");
}

#melco-configurator *, #melco-configurator :before, #melco-configurator :after, #melco-configurator ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

@media (min-width: 1024px) {
  #melco-configurator .mfc__hidden-desktop {
    display: none;
  }
}

#melco-configurator * {
  font-family: Helvetica Neue LT W05_55 Roman, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

#melco-configurator h1 {
  font-family: Helvetica Neue LT W05_65 Medium, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}

#melco-configurator h1, #melco-configurator h2, #melco-configurator h3, #melco-configurator h4, #melco-configurator h5, #melco-configurator h6, #melco-configurator .mfc__modal, #melco-configurator .mfc__confirm {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

/*# sourceMappingURL=melco-configurator.es.css.map */
