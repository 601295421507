@tailwind components;

@layer components {
  .mfc__personalize-edit-item {
    @apply mfc__grow;
    @apply mfc__overflow-hidden;
  }

  .mfc__personalize-edit-item .mfc__personalize-edit-item-inner {
    @apply mfc__flex mfc__grow mfc__justify-center mfc__items-center;
    @apply mfc__p-2;
    @apply mfc__bg-white mfc__rounded-md mfc__border;
  }
  .mfc__personalize-edit-item-error .mfc__personalize-edit-item-inner {
    @apply mfc__border-error;
  }

  .mfc__personalize-edit-item-error .mfc__personalize-edit-item-help,
  .mfc__personalize-edit-item-error .mfc__personalize-edit-item-inner-suffix {
    @apply mfc__text-error;
  }

  .mfc__personalize-edit-item-help {
    @apply mfc__px-4 mfc__py-2;
    @apply mfc__text-left;
    @apply mfc__text-sm;
    @apply mfc__text-gray-500;
  }

  .mfc__personalize-edit-item-inner-suffix {
    @apply mfc__text-sm;
    @apply mfc__text-gray-500;
  }

  /* Characteristics */
  .mfc__personalize-edit-item-full {
    @apply mfc__basis-full;
  }
  
  .mfc__personalize-edit-item-half {
    /* compensating gap-2 */
    flex-basis: calc(50% - theme(spacing.2));
  }
}
