@tailwind components;

@layer components {
  .mfc__modal-sidebar {
    @apply mfc__z-20; /* canvas of renderer always has z-index 10. Sidebar should be higher because of shadow on mobile */
    @apply mfc__relative;
    @apply mfc__flex mfc__justify-center;
    @apply mfc__overflow-hidden;
    @apply mfc__h-full;
    @apply mfc__bg-gray-100 mfc__border-t;
    @apply mfc__text-center;
    @apply mfc__shadow-tsm;

    /* Desktop */
    @apply lg:mfc__border-t-0 lg:mfc__border-l;
    @apply lg:mfc__w-[26.25rem];
    @apply lg:mfc__rounded-r-xl;
    @apply lg:mfc__shadow-none;
  }

  .mfc__modal-sidebar-inner {
    @apply mfc__w-[26.25rem];

    /* Desktop */
    @apply lg:mfc__w-full;
  }

  .mfc__modal-sidebar-inner > h1 {
    @apply mfc__p-11 mfc__pb-0;
  }

  .mfc__modal-sidebar-inner {
    @apply mfc__h-full;
    @apply mfc__overflow-y-auto;
  }
}
