@tailwind components;

@layer components {
  .mfc__confirm {
    @apply mfc__z-[1002];
    @apply mfc__fixed mfc__inset-0;
    @apply mfc__w-full mfc__h-full;
    @apply mfc__overflow-y-auto mfc__overflow-x-hidden;
    @apply mfc__flex mfc__justify-center mfc__items-center;
  }

  .mfc__confirm-inner {
    @apply mfc__relative;
    @apply mfc__overflow-hidden;
    @apply mfc__w-auto;
    @apply mfc__m-4;
    @apply mfc__shadow-lg;
    @apply mfc__bg-white;
    @apply mfc__rounded-xl;
  }

  .mfc__confirm-content {
    @apply mfc__relative;
    @apply mfc__h-full;
    @apply mfc__overflow-hidden;
    @apply mfc__flex mfc__items-center mfc__justify-center mfc__flex-col mfc__space-y-2;
    @apply mfc__p-8;
    @apply mfc__text-center;
    @apply mfc__text-base;
  }

  .mfc__confirm-title {
    @apply mfc__font-medium;
    @apply mfc__text-base;
  }

  .mfc__confirm-buttons {
    @apply mfc__pt-4;
    @apply mfc__flex mfc__justify-center;
    @apply mfc__gap-2;
  }
}
