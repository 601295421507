/* Web Font Import */
@import url("//fast.fonts.net/t/1.css?apiType=css&projectid=832196c4-86bd-450e-ba18-2fb3cf7bd85c");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Helvetica Neue LT W05_55 Roman";
    src: url("./fonts/08b57253-2e0d-4c12-9c57-107f6c67bc49.woff2")
        format("woff2"),
      url("./fonts/08edde9d-c27b-4731-a27f-d6cd9b01cd06.woff") format("woff");
  }
  @font-face {
    font-family: "Helvetica Neue LT W05_65 Medium";
    src: url("./fonts/240c57a0-fdce-440d-9ce3-85e0cb56f470.woff2")
        format("woff2"),
      url("./fonts/7802e576-2ffa-4f22-a409-534355fbea79.woff") format("woff");
  }
}
@layer components {
  .mfc__hidden-desktop {
    @apply lg:mfc__hidden;
  }

  * {
    @apply mfc__font-sans;
  }

  h1 {
    @apply mfc__font-medium mfc__text-xl;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply mfc__text-black;
  }

  .mfc__modal,
  .mfc__confirm {
    @apply mfc__text-black;
  }
}
