@tailwind components;

@layer components {
  .mfc__personalize-edit-section {
    @apply mfc__text-center;
  }

  .mfc__personalize-edit-section:not(:first-of-type) {
    @apply before:mfc__block;
    @apply before:mfc__border-t before:mfc__mt-8;
  }

  .mfc__personalize-edit-section-inner {
    @apply mfc__flex mfc__flex-wrap mfc__gap-2;
    @apply mfc__m-11;
  }

  .mfc__personalize-edit-section-inner h2 {
    @apply mfc__text-base;
    @apply mfc__basis-full;
    @apply mfc__mb-2;
  }

  .mfc__personalize-edit-section button {
    @apply mfc__grow;
  }
}
