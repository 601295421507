@tailwind components;

@layer components {
  .mfc__modal-sidebar-scroll-indicator {
    @apply mfc__absolute mfc__bottom-11 mfc__animate-mfc__bounce;
    @apply mfc__opacity-40;
  }
  .mfc__modal-sidebar-scroll-indicator > svg {
    @apply mfc__drop-shadow-md;
  }
}
