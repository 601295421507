@tailwind components;

@layer components {
  .mfc__modal {
    @apply mfc__z-[1001];
    @apply mfc__fixed mfc__inset-0;
    @apply mfc__w-full mfc__h-full;
    @apply mfc__overflow-y-auto mfc__overflow-x-hidden;
    @apply mfc__flex mfc__justify-center mfc__items-center;
    @apply mfc__text-base;
    @apply mfc__leading-none;
  }

  .mfc__modal-inner {
    @apply mfc__relative;
    @apply mfc__overflow-hidden;
    @apply mfc__w-full mfc__max-w-5xl mfc__h-full;
    @apply mfc__shadow-lg;
    @apply mfc__bg-white;

    /* Desktop */
    @apply lg:mfc__rounded-xl lg:mfc__h-2/3;

    /* Desktop large */
    @apply xl:mfc__max-w-7xl;
  }

  .mfc__modal-close {
    @apply mfc__z-[1002];
    @apply mfc__absolute mfc__top-8 mfc__right-8;
  }

  .mfc__loader {
    @apply mfc__z-[1004];
  }

  .mfc__modal-content {
    @apply mfc__relative;
    @apply mfc__h-full;
    @apply mfc__overflow-hidden;
    @apply mfc__flex mfc__justify-between mfc__flex-col;

    /* Desktop */
    @apply lg:mfc__flex-row;
  }
}
