@tailwind components;

@layer components {
  .mfc__floating-btn-grid {
    @apply mfc__flex mfc__justify-center;
  }
  .mfc__floating-btn-grid-labeled {
    @apply mfc__basis-full;
    @apply mfc__mt-4;
    @apply mfc__p-4;
    @apply mfc__bg-white mfc__rounded-md mfc__border;
  }
  .mfc__floating-btn-grid-inner {
    @apply mfc__grid mfc__grid-cols-4 mfc__gap-4;

    @apply xs:mfc__grid-cols-5;
  }
  .mfc__floating-btn-grid-inner-small {
    @apply mfc__grid-cols-3;
  }
}
