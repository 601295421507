@tailwind components;

@layer components {
  .mfc__input-field {
    @apply mfc__w-full;
    @apply mfc__px-2 mfc__py-1;
    @apply mfc__bg-white;
    @apply mfc__text-base;
    @apply mfc__leading-none;
  }

  .mfc__input-field:focus {
    @apply mfc__outline-none;
  }
}
