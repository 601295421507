@tailwind components;

@layer components {
  .mfc__confirm-backdrop {
    @apply mfc__z-[1001];
    @apply mfc__fixed mfc__inset-0;
    @apply mfc__overflow-y-auto;
    @apply mfc__h-full mfc__w-full;
    @apply mfc__bg-gray-600 mfc__bg-opacity-80;
  }
}
