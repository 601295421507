@tailwind components;

@layer components {
  .mfc__switch-item {
    @apply mfc__transition-transform;
    @apply mfc__inline-flex mfc__items-center mfc__justify-center;
    @apply mfc__w-12 mfc__h-full;
    @apply mfc__rounded-full;

    /* States */
    @apply hover:mfc__border hover:mfc__border-gray-100;

    @apply active:mfc__border active:mfc__border-gray-300 active:mfc__shadow-sm;

    @apply disabled:hover:mfc__border-0;
    @apply disabled:active:mfc__border-0 disabled:active:mfc__shadow-none;
  }

  .mfc__switch-item > * {
    @apply mfc__max-w-[1.75rem];
  }

  /* States */
  .mfc__switch-item:not(:disabled) > * {
    @apply active:mfc__z-[1000];
    @apply active:mfc__translate-y-[0.5px];
  }

  .mfc__switch-item.mfc__switch-item-active {
    @apply mfc__border mfc__border-gray-900;

    /* States */
    @apply hover:mfc__border-gray-400;

    @apply active:mfc__border-gray-900;

    @apply disabled:hover:mfc__border disabled:hover:mfc__border-gray-900;
  }
}
