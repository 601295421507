@tailwind components;

@layer components {
  .mfc__configurator-zoom-button {
    @apply mfc__z-20;
    @apply mfc__absolute;
    @apply mfc__p-8;

    @apply mfc__bottom-0 mfc__right-0;

    /* Desktop */
    @apply lg:mfc__top-0;
  }

  .mfc__configurator_view_mode .mfc__configurator-zoom-button {
    @apply lg:mfc__right-16;
  }
}
