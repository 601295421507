@tailwind components;

@layer components {
  .mfc__switch {
    @apply mfc__h-12;
    @apply mfc__bg-white mfc__rounded-full;
    @apply mfc__shadow-md;
  }

  /* States */
  .mfc__switch-disabled {
    @apply mfc__opacity-40;
  }
}
